import React from 'react';
import './ProfProfile.scss';

class ProfProfile extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div className='profprofile'>
                <h1>Professor's Profile</h1>
                <h2>LS Storch</h2>
                <p><a href="mailto:languagewizard@outlook.com" className='emailcontact'>📧</a><span className='phonecontact'>: languagewizard@outlook.com</span></p>
                <p className='phonecontact'>📱: 917-410-1328</p>
            </div>
        )
    }
}

export default ProfProfile;