import React from 'react';
import './PhoneticsThree.scss';
import { Tabs, Tab, ListGroup, Container, Image, Col, Row } from 'react-bootstrap';
import consonantplace from './1.3.1.2.png';
import sonorityhierarchy from './1.3.1.3.png';

class PhoneticsThree extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <div className='lessoncontent'>
                    <h1 className='modulesubtitle text-center'>Consonants</h1>
                    <p className='lessontext subone'>1.3 Consonants involve relative constriction of the vocal tract, compared to vowels. The word itself can be broken down into con 'with' plus sonant or 'sound'. Consonants thus go 'with the sound', aka the vowel. The combination of consonant and vowel forms a syllable, a larger unit which we will discuss in Module 5.</p>
                    <p className='lessontext subtwo'>1.3.1 Consonants are differentiated from each other in three main ways:</p>
                    <p className='lessontext subthree'>1.3.1.1 Laryngeal Setting (Voicing): Consonant can be voiced, with vocal fold vibration, or voiceless, without. In addition, consonants may be aspirated, breathy voiced, creaky voiced and so forth. This is controlled by the glottis, the opening in the larynx which lets air through. When the glottis is closed, voiced sounds are produced (because air sputters through, vibrating the vocal folds), while when it is open voiceless sounds are produced (because air can escape freely, without causing vibration).</p>
                    <p className='lessontext subthree'>1.3.1.2 Place of Articulation: Consonants determine a certain configuration of articulators such as the tongue, lips, jaw, velum, etc. Where each is positioned in relation to the others is known as 'place' of articulation. The relevant organs of the vocal tract are the lips, teeth, tongue apex (or tip), tongue blade (or lamina, the area right behind the tip), tongue body (the 'middle' of the tongue), tongue root (the base of the tongue), alveolar ridge, palate, velum, uvula, and glottis (see first diagram below). There are 13 possible consonant places of articulation: bilabial, labiodental, interdental, dental, alveolar, post-alveolar (aka palatoalveolar), retroflex, alveolo-palatal, palatal, velar, uvular, glottal, and epiglottal (see second diagram below). In describing consonant place, keep in mind the distinction between active (or moving) articulators, as opposed to passive (or static) articulators. For example, in the sound 't', the alveolar ridge is the passive articulator, while the tongue is the active. What are the active/passive articulators for the sounds 'p', 'k' and 'h'? In general, the lower surface of the mouth is more active, while the upper is more passive. We do not talk like Elmo.</p>
                    <div className='imagediv'>
                        <Image src={consonantplace} className='lessonimg' />
                    </div>
                    <Container fluid>
                        <Row>
                            <Col>
                                <p className='lessontext subthree'>1.3.1.3 Manner of Articulation: Consonant 'manner' can be thought of on a spectrum from most to least constriction created in the vocal tract. This is known as the Sonority Hierarchy, and a diagram is given below. Sonority can be thought of in terms of constriction: more sonorous sounds have less constriction. For example, compare the articulation of 't' and 'a'. Which has more constriction? The various manners of articulation are given below the diagram, in their order on the Sonority Hierarchy from least to most sonorous. Note that vowels are more sonorous than all consonants.</p>
                            </Col>
                            <Col>
                                <Image src={sonorityhierarchy} className='lessonimginline' />
                            </Col>
                        </Row>
                    </Container>
                    <p className='lessontext subfour'>1.3.1.3.1 Stops (aka plosives) are consonants in which the airflow is indeed stopped for a significant duration by the active and passive articulators coming into full contact. Ex: t, p, k, d, b, g</p>
                    <p className='lessontext subfour'>1.3.1.3.2 Affricates can be thought of as sounds that begin as stops but end as fricatives. Ex: ch, j</p>
                    <p className='lessontext subfour'>1.3.1.3.3 Fricatives are like stops, except that they have a small gap, rather than fully coming into contact. This allows air to 'leak' out, causing frication in the speech signal, the hallmark sound of fricatives. Rather than being continuous waves, this frication is an unordered pattern, as air molecules randomly seep through the cracks. Ex: s, z, sh, f, v, h, th</p>
                    <p className='lessontext subfour'>1.3.1.3.4 Flaps/taps are the same as stops except they have a shorter duration. In these sounds the tongue or other articulators fully come into contact, but for a shorter duration than with stop consonants. Ex: the middle sound (written 't') in the word 'cities'.</p>
                    <p className='lessontext subfour'>1.3.1.3.5 Trills are consonants in which the active or passive articulator vibrates in the air currents leaving the mouth. It motion is thus unconscious. Ex: Spanish trilled 'rr'.</p>
                    <p className='lessontext subfour'>1.3.1.3.6 Nasals are sounds in which air flows through the nasal passages. Vowels may be nasalized as well as consonants. The placement of the velum controls whether sounds are nasalized: if it is raised, we get oral sounds, because airflow is blocked through the nasal passage, while if it is lowered we get nasal sounds, because air is allowed to flow through the nose. Ex: m, n, ng</p>
                    <p className='lessontext subfour'>1.3.1.3.7 Approximants are the consonants which are highest on the Sonority Hierarchy, though vowels are higher than them. Thus, they have the least constriction of any consonants. They can be subdivided as follows:</p>
                    <p className='lessontext subtwo'>1.3.2 Obstruent is a cover term that includes stops, fricatives and affricates. All other sounds are considered sonorants (this is a binary distinction).</p>
                    <p className='lessontext subtwo'>1.3.3 Voice Onset Time (VOT) is a measurement of the time between the release burst of a stop and the onset of voicing in the larynx. It can be positive, indicating aspiration, zero, indicating voicelessness, or negative, indicating voiced sounds.</p>
                </div>
                <Tabs defaultActiveKey="wikipedia" id="uncontrolled-tab-example">
                    <Tab className='linkstab' eventKey="wikipedia" title="Wikipedia">
                        <ListGroup className='tablist'>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='' rel="noreferrer" target="_blank">→ </a></ListGroup.Item>
                        </ListGroup>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default PhoneticsThree;