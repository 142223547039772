import React from 'react';
import './Home.scss';

class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <h1>Welcome to Language Wizard!</h1>
                <p>This site is devoted to everything lingusitics.</p>
                <p>Specifically, it is now a course in Phonology, but more Syntax, Morphology, etc will be added.</p>
            </div>
        )
    }
}

export default Home;