import React from 'react';
import './PhoneticsOverview.scss';
import { Jumbotron, ListGroup, ButtonGroup, Button, Tabs, Tab, Container, Row, Col } from 'react-bootstrap';

class PhoneticsOverview extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div className='overview'>
                <Jumbotron className='titleandsummary'>
                    <h1 className='text-center moduletitle'>1.0 Phonemes: Overview</h1>
                    <p className='modsummary text-center'><em>
                        In this module, we will do a brief review of production in phonetics, 
                        as well as learn to do IPA transcription and take a brief look at linguistic typology.
                    </em></p>
                </Jumbotron>
                <ButtonGroup className='mainbuttons'>
                    <Button className='mainbutton' variant='danger'>Slides</Button>
                    <Button className='mainbutton' variant='danger'>Lecture Recording</Button>
                    <Button className='mainbutton' variant='danger'>Lecture Notes</Button>
                </ButtonGroup>
                <Container fluid>
                    <Row>
                        <Col>
                            <ListGroup className='learningobjectives'>
                                <ListGroup.Item className='learnobjheader'>After this module you should be able to:</ListGroup.Item>
                                <ListGroup.Item className='learnobj'>• Explain what phonology is, why it is useful and how it differs from other related fields.</ListGroup.Item>
                                <ListGroup.Item className='learnobj'>• Transcribe English speech in IPA.</ListGroup.Item>
                                <ListGroup.Item className='learnobj'>• Analyze speech sounds in terms of place and manner of articulation.</ListGroup.Item>
                                <ListGroup.Item className='learnobj'>• Define relevant phonetics terminology.</ListGroup.Item>
                                <ListGroup.Item className='learnobj'>• Compare and contrast the languages of the world.</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col>
                            <Tabs defaultActiveKey="textbook" id="uncontrolled-tab-example" className='tabgroup'>
                                <Tab className='linkstab' eventKey="textbook" title="Textbook">
                                    <ListGroup className='tablist'>
                                        <ListGroup.Item className='tablistitem'><a rel="noreferrer" href='https://drive.google.com/file/d/1CGdIOwS0hqEt7F49DtM6NG8eI32G5lfc/view' target='_blank'>-- Hayes' Introductory Phonology, Chapter 1 'Phonetics'</a></ListGroup.Item>
                                        <ListGroup.Item className='tablistitem'>-- Kennedy's Phonology: A Coursebook, Chapter 1 'Introduction'</ListGroup.Item>
                                        <ListGroup.Item className='tablistitem'>-- Odden's Introducing Phonology, Chapter 1 'What is Phonology'</ListGroup.Item>
                                        <ListGroup.Item className='tablistitem'>-- Kenstowicz's Phonology in Generative Grammar, Chapter 1 'The Sounds of Speech'</ListGroup.Item>
                                        <ListGroup.Item className='tablistitem'><a rel="noreferrer" href='https://drive.google.com/file/d/1_IzHpEzeJu6atQBvWjJzCGes9j_TvY4I/view' target='_blank'>-- A Manual of English Phonetics and Phonology by Skandera and Burleigh, Lessons 1-4</a></ListGroup.Item>
                                    </ListGroup>
                                </Tab>
                                <Tab className='linkstab' eventKey="wikipedia" title="Wikipedia">
                                    <ul>
                                        <li><a className='tablistitem' rel="noreferrer" href='https://en.wikipedia.org/wiki/Phonetics' target="_blank">Phonetics</a></li>
                                    </ul>
                                </Tab>
                                <Tab className='linkstab' eventKey="youtube" title="YouTube">
                                    <ul>
                                        <li><a className='tablistitem' rel="noreferrer" href='https://www.youtube.com/watch?v=GLBsvdaR_ow' target="_blank">Phonetics: Intro to linguistics [Video 2]</a></li>
                                    </ul>
                                </Tab>
                                <Tab className='linkstab' eventKey="articles" title="Journal Articles">
                                    <ul>
                                        <li><a className='tablistitem' rel="noreferrer" href='https://drive.google.com/file/d/1eZUr9wWTtV4TjTtjxzMkCjRYGYAxqoZ0/view' target='_blank'>Measuring language-specific phonetic settings by Mennen et al</a></li>
                                        <li><a className='tablistitem' rel="noreferrer" href='https://drive.google.com/file/d/1HeSeVfQJiqe4yEv7If6gl3T79p3ckkoz/view' target='_blank'>Phonology and phonetics review by Rogerson-Rivell</a></li>
                                    </ul>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
                
                
            </div>
        )
    }
}

export default PhoneticsOverview;