import React from 'react';
import './Profile.scss';

class Profile extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <p>Profile</p>
            </div>
        )
    }
}

export default Profile;