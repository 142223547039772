import React from 'react';
import './Phonetics.scss';
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Nav, Container, Row, Col } from 'react-bootstrap';
import PhoneticsOverview from './PhoneticsOverview/PhoneticsOverview';
import PhoneticsOne from './PhoneticsOne/PhoneticsOne';
import PhoneticsQuiz from './PhoneticsQuiz/PhoneticsQuiz';
import PhoneticsThree from './PhoneticsThree/PhoneticsThree';
import PhoneticsExercises from './PhoneticsExercises/PhoneticsExercises';
import PhoneticsHomework from './PhoneticsHomework/PhoneticsHomework';

class Phonetics extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div className='appy'>
                <Router>
                    <Container fluid>
                        <Row>
                            <Col sm={2}>
                                <Nav defaultActiveKey="/home" className="flex-column vertnavbar">
                                    <Nav.Link><Link to='/modules/phonemes/overview'>1.0 Phonemes Overview</Link></Nav.Link>
                                    <Nav.Link><Link to='/modules/phonemes/lessonone'>1.1 Experimental Techniques</Link></Nav.Link>
                                    <Nav.Link><Link to='/modules/phonemes/lessontwo'>1.2 Source-Filter Theory</Link></Nav.Link>
                                    <Nav.Link><Link to='/modules/phonemes/lessonthree'>1.3 Consonants</Link></Nav.Link>
                                    <Nav.Link><Link to='/modules/phonemes/quiz'>A. Quiz</Link></Nav.Link>
                                    <Nav.Link><Link to='/modules/phonemes/exercises'>B. Exercises</Link></Nav.Link>
                                    <Nav.Link><Link to='/modules/phonemes/homework'>C. Homework</Link></Nav.Link>
                                </Nav>
                            </Col>
                            <Col sm={10}>
                                <Switch>
                                    <Route path='/modules/phonemes/overview'>
                                        <PhoneticsOverview />
                                    </Route>
                                    <Route path='/modules/phonemes/lessonone'>
                                        <PhoneticsOne />
                                    </Route>
                                    <Route path='/modules/phonemes/lessonthree'>
                                        <PhoneticsThree />
                                    </Route>
                                    <Route path='/modules/phonemes/quiz'>
                                        <PhoneticsQuiz />
                                    </Route>
                                    <Route path='/modules/phonemes/exercises'>
                                        <PhoneticsExercises />
                                    </Route>
                                    <Route path='/modules/phonemes/homework'>
                                        <PhoneticsHomework/>
                                    </Route>
                                    <Route path='/modules/phonemes'>
                                        <PhoneticsOverview />
                                    </Route>
                                </Switch>
                            </Col>
                        </Row>
                    </Container>
                </Router>
            </div>
        )
    }
}

export default Phonetics;