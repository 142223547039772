import React, { useState, useEffect } from 'react'; 
import './IPADescription.scss';
import { Form, Button, Col, Container, Row, Card } from 'react-bootstrap';
import { API } from 'aws-amplify';



function IPADescription() {
    const [place, setPlace] = useState('bilabial')
    const [manner, setManner] = useState('glide')
    const [voicing, setVoicing] = useState('voiced')
    const [height, setHeight] = useState('high')
    const [backness, setBackness] = useState('front')
    const [rounding, setRounding] = useState('unround')
    const [ipa, setIPA] = useState('t')

    function handleIPAChange (event) {
        setIPA(event.target.value)
    }

    function handlePlaceChange (event) {
        setPlace(event.target.value)
    }

    function handleMannerChange (event) {
        setManner(event.target.value)
    }

    function handleVoicingChange (event) {
        setVoicing(event.target.value)
    }

    function handleHeightChange (event) {
        setHeight(event.target.value)
    }

    function handleBacknessChange (event) {
        setBackness(event.target.value)
    }

    function handleRoundingChange (event) {
        setRounding(event.target.value)
    }

    function handleSubmit (event) {
        fetchResult()
    }

    const params = {
        ipa: ipa,
        place: place,
        manner: manner,
        voicing: voicing,
        height: height,
        backness: backness,
        rounding: rounding,
        resultipa: '',
        resultcons: '',
        resultvowel: '',
    }


    const [resultipa, setResultipa] = useState(null)
    const [resultcons, setResultcons] = useState(null)
    const [resultvowel, setResultvowel] = useState(null)

    async function fetchResult() {
        const apiData = await API.get('langwiz', '/ipa-description', {queryStringParameters: params})
        setResultipa(apiData.resultipa)
        setResultcons(apiData.resultcons)
        setResultvowel(apiData.resultvowel)
      }
      useEffect(() => {
        fetchResult()
      }, [])
        
    return (
        <div>
            <Card.Title className='text-center appytitle'>IPA ⟷ Description</Card.Title>
            <Form.Group>
                <Container fluid>
                    <Row>
                        <Col>
                            <Card className='appycard'>
                                <div className='cardcontent'>
                                    <Card.Title className='text-center appysubtitle'>IPA to Description</Card.Title>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                        Enter IPA:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control className='forminput' type="text" placeholder="t" onChange={handleIPAChange}/>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                                    </Form.Row>
                                    <p className='result'>>>> <span className='tempresult'><em>{resultipa}</em></span></p>
                                </div>
                            </Card>  
                        </Col>
                        <Col>
                            <Card className='appycard'>
                                <div className='cardcontent'>
                                    <Card.Title className='text-center appysubtitle'>Consonant Description to IPA</Card.Title>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                        Choose Voicing:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control as="select" onChange={handleVoicingChange}>
                                                <option>voiced</option>
                                                <option>voiceless</option>
                                                <option>voiceless aspirated</option>
                                                <option>voiced aspirated</option>
                                                <option>breathy</option>
                                                <option>voiceless long</option>
                                                <option>voiced long</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                            Choose Place:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control as="select" onChange={handlePlaceChange}>
                                                <option>bilabial</option>
                                                <option>labiodental</option>
                                                <option>interdental</option>
                                                <option>dental</option>
                                                <option>alveolar</option>
                                                <option>post-alveolar</option>
                                                <option>retroflex</option>
                                                <option>alveolo-palatal</option>
                                                <option>palatal</option>
                                                <option>velar</option>
                                                <option>uvular</option>
                                                <option>pharyngeal</option>
                                                <option>glottal</option>
                                                <option>epiglottal</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                        Choose Manner:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control as="select" onChange={handleMannerChange}>
                                                <option>glide</option>
                                                <option>rhotic (approx)</option>
                                                <option>lateral</option>
                                                <option>trill</option>
                                                <option>tap</option>
                                                <option>nasal</option>
                                                <option>fricative</option>
                                                <option>affricate</option>
                                                <option>stop</option>
                                                <option>click</option>
                                                <option>implosive</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                                    </Form.Row>
                                    <p className='result'>>>> <span className='tempresult'><em>{resultcons}</em></span></p>
                                </div>
                            </Card>     
                        </Col>
                        <Col>
                            <Card className='appycard'>
                                <div className='cardcontent'>
                                    <Card.Title className='text-center appysubtitle'>Vowel Description to IPA</Card.Title>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                        Choose Height:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control as="select" onChange={handleHeightChange}>
                                                <option>high</option>
                                                <option>mid-high</option>
                                                <option>mid</option>
                                                <option>mid-low</option>
                                                <option>low</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                        Choose Backness:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control as="select" onChange={handleBacknessChange}>
                                                <option>front</option>
                                                <option>central</option>
                                                <option>back</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='appyformrow'>
                                        <Form.Label column>
                                        Choose Rounding:
                                        </Form.Label>
                                        <Col>
                                            <Form.Control as="select" onChange={handleRoundingChange}>
                                                <option>unround</option>
                                                <option>round</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                                    </Form.Row>
                                    <p className='result'>>>> <span className='tempresult'><em>{resultvowel}</em></span></p>
                                </div>
                            </Card> 
                        </Col>
                    </Row>
                </Container>
            </Form.Group>
        </div>
    );
  }

export default IPADescription;