import React from 'react';
import Appys from '../Appys/Appys';
import Allophones from '../Modules/Allophones/Allophones';
import Features from '../Modules/Features/Features';
import Phonetics from '../Modules/Phonetics/Phonetics';
import Profile from '../Profile/Profile';
import './Header.scss';
import { 
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Navbar, Nav, NavDropdown, Button, Form, FormControl } from 'react-bootstrap';
import Home from '../Home/Home';
import { RiAppsFill } from 'react-icons/ri';
import { CgProfile } from 'react-icons/cg';
import headerpic from './header.png';
import ProfProfile from '../ProfProfile/ProfProfile';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <Router>
                    <Navbar bg="info" expand="lg" variant='dark'>
                        <Navbar.Brand><Link to='/home' className='langwiztitle'>[🗣️] Langgwiz [🧙]</Link></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link><Link to='/profile' className='navlink firstnavlink'>👤</Link></Nav.Link>
                                <Nav.Link><Link to='/appys' className='navlink'><RiAppsFill /></Link></Nav.Link>
                                <Nav.Link><Link to='/profprofile' className='navlink'>👨‍🏫</Link></Nav.Link>
                                <NavDropdown title={<span className='navlinkdropdown'>Modules</span>} id="basic-nav-dropdown">
                                    <NavDropdown.Item className='navlinkdropdownlink'><Link to='/modules/phonemes'>1. Phonetics</Link></NavDropdown.Item>
                                    <NavDropdown.Item className='navlinkdropdownlink'><Link to='/modules/allophones'>2. Allophones</Link></NavDropdown.Item>
                                    <NavDropdown.Item className='navlinkdropdownlink'><Link to='/modules/features'>3. Features</Link></NavDropdown.Item>
                                    <NavDropdown.Item className='navlinkdropdownlink'><Link to='/modules/syllables'>4. Syllables</Link></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item>Separated link</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Form inline>
                                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                                <Button variant="outline-success">Search</Button>
                            </Form>
                        </Navbar.Collapse>
                    </Navbar>
                    <div className='headerpicdiv'>
                        <img src={headerpic} className='headerpic'/>
                    </div>
                    <Switch>
                        <Route path='/profile'>
                            <Profile />
                        </Route>
                        <Route path='/appys'>
                            <Appys />
                        </Route>
                        <Route path='/profprofile'>
                            <ProfProfile />
                        </Route>
                        <Route path='/modules/phonemes'>
                            <Phonetics />
                        </Route>
                        <Route path='/modules/allophones'>
                            <Allophones />
                        </Route>
                        <Route path='/modules/features'>
                            <Features />
                        </Route>
                        <Route path='/home'>
                            <Home />
                        </Route>
                        <Route path='/'>
                            <Home />
                        </Route>
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default Header;