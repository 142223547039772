import React from 'react';
import './Allophones.scss';

class Allophones extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <p>Allophones</p>
            </div>
        )
    }
}

export default Allophones;