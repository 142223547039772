import React from 'react';
import { Col, Container, Row, Form, Button, ListGroup } from 'react-bootstrap';
import './PhoneticsHomework.scss';

class PhoneticsHomework extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <ListGroup>
                    <ListGroup.Item className='exerciseone exercise'>
                        <Container fluid>
                            <Row>
                                <Col className='exercisetitle text-center'>
                                    Part I: Basic IPA Transcription Exercises
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisequestion'>
                                    1. How many distinct sounds are in each of the following words? For example, the word 'three' has 3 distinct sounds. [1 point]
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>a. laugh</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>b. begged</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>c. graphic</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>d. fish</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>e. fishes</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>f. fished</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>g. batting</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>h. quick</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>i. these</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>          <div className='exerciseinput'>
                                                    <Form.Label column>j. physics</Form.Label>
                                                    <Form.Control placeholder="enter number of sounds" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubmitcol'>
                                    <Button className='exercisesubmit'>Submit</Button>
                                </Col>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        )
    }
}

export default PhoneticsHomework;