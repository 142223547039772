import React from 'react';
import './Appys.scss';
import IPADescription from './IPADescription/IPADescription';

class Appys extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <IPADescription />
            </div>
        )
    }
}

export default Appys;