import React from 'react';
import './PhoneticsOne.scss';
import { Tabs, Tab, ListGroup, Card, Container, Col, Row } from 'react-bootstrap';
import palatography from './1.1.1.png';
import ema from './1.1.2.png';
import ultrasound from './1.1.3.png';
import mri from './1.1.4.png';

class PhoneticsOne extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <Card className='lessoncontent'>
                                <Card.Body className='lessontext'>
                                    <Card.Title className='modulesubtitle text-center'>Experimental Techniques</Card.Title>
                                    <Card.Text>1.1 Phonetics Experimental Technologies are used to gather data in phonetics. We cannot see the motions of the tongue while speaking for it is obscured by the mouth. Therefore how do we see such motion? Four answers are outlined below. It is methodologies like these that have allowed phoneticians to classify consonants and vowels as we will see presently.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='lessoncontent'>
                                <Card.Body >
                                    <Card.Title className='topictitle text-center'>Palatography</Card.Title>
                                    <Card.Img className='palatographyimg' variant='top' src={palatography}/>
                                    <Card.Text className='lessontext'>
                                    1.1.1 Palatography uses a gum or resin, which is applied to the tongue or other active articulator for a given sound. The subject is then asked to say that sound, and in this process the gum is transferred from active to passive articulator. Afterward the experimenter can examine the subject's mouth to see where exactly the gum is located, showing what point of contact is made for the sound (see 'place of articulation' in 1.3)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='lessoncontent'>
                                <Card.Body>
                                    <Card.Title className='topictitle text-center'>Electromagnetic Articulography</Card.Title>
                                    <Card.Img className='emaimg' variant='top' src={ema} />
                                    <Card.Text className='lessontext'>1.1.2 Electromagnetic Articulography (EMA) uses small magnetic electrodes affixed or glued to parts of the vocal tract with an adhesive. The subject speaks within a magnetic-sensing enclosure, which captures the movement of the affixed magnets which can be used to extrapolate the movement of articulators in speech. Though EMA may be uncomfortable it is not painful and only minimally invasive.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className='lessoncontent'>
                                <Card.Body className='lessontext'>1.1.3 Ultrasound is a technique that uses sound waves to image internal structures, somewhat like echolocation. These sound waves are above the threshold of human hearing, so we cannot detect them with our ears. Of course ultrasound is often used during pregnancy, but it has many other applications as well, such as in phonetics. Ultrasound is minimally invasive and often used to image the motion of the tongue and other internal articulators during speech.</Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='lessoncontent'>
                                <Card.Body className='lessontext'>1.1.4 MRI, or Magnetic Resonance Imaging, works similarly to ultrasound except that it uses magnetism to image the inside of the mouth. MRI has the advantage of creating very precise images, but the disadvantage of being very expensive and requiring very large bulky apparatuses, at least compared to ultrasound.</Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Tabs defaultActiveKey="wikipedia" id="uncontrolled-tab-example">
                    <Tab className='linkstab' eventKey="wikipedia" title="Wikipedia">
                        <ListGroup className='tablist'>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://en.wikipedia.org/wiki/Palatography' rel="noreferrer" target="_blank">→ Palatography</a></ListGroup.Item>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://en.wikipedia.org/wiki/Electromagnetic_articulography' target='_blank' rel="noreferrer">→ Electro-Magnetic Articulography</a></ListGroup.Item>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://en.wikipedia.org/wiki/Medical_ultrasound' target='_blank' rel="noreferrer">→ Ultrasound</a></ListGroup.Item>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://en.wikipedia.org/wiki/Magnetic_resonance_imaging' target='_blank' rel="noreferrer">→ Magnetic Resonance Imaging</a></ListGroup.Item>
                        </ListGroup>
                    </Tab>
                    <Tab className='linkstab' eventKey="youtube" title="YouTube">
                        <ListGroup className='tablist'>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://www.youtube.com/watch?v=wCDuh6dfw6g' target='_blank' rel="noreferrer">→ Palatography Lessons 1: Equipment</a></ListGroup.Item>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://www.youtube.com/watch?v=6oIejoZ17j0' target='_blank' rel="noreferrer">→ The Electromagnetic Articulograph (EMA) at the Centre for Research on Brain, Language, and Music</a></ListGroup.Item>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://www.youtube.com/watch?v=b9URTdEe_Ko' target='_blank' rel="noreferrer">→ Introduction to Ultrasound Technology in Linguistics</a></ListGroup.Item>
                            <ListGroup.Item className='tablistitem'><a className='tablistitem' href='https://www.youtube.com/watch?v=J3TwTb-T044' target='_blank' rel="noreferrer">→ Singing in the MRI with Tyley Ross - Making the Voice Visible</a></ListGroup.Item>
                        </ListGroup>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default PhoneticsOne;