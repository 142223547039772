import React from 'react';
import './App.scss';
import Header from '../Header/Header';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    return (
      <div>
        <Header />
      </div>
    )
  }
}

export default App;
