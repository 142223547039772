import React from 'react';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import './PhoneticsExercises.scss';

class PhoneticsExercises extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <ListGroup>
                    <ListGroup.Item className='exerciseone exercise'>
                        <Container fluid>
                            <Row>
                                <Col className='exercisetitle text-center'>
                                    Exercise 1: IPA to English
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisequestion'>
                                    1. Write the following English words in the English alphabet:
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>a. pərˈteɪn</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>b. ˈslɪŋˌʃɑt</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>c. ˈbeɪbi</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>d. ˈvihɪkəl</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>e. ˈbændɪʤ</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>f. ˈgrætəˌtud</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>g. ˈflɪŋɪŋ</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>h. ˈʃoʊɪŋ</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>i. ʧɚʧ</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>j. hiz</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubmitcol'>
                                    <Button className='exercisesubmit'>Submit</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisequestion'>
                                    2. Transcribe the following IPA paragraph into English:
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>a. ðə snoʊ wʌz ˈfɑlɪŋ, ænd ðə kæts fɚ wʌz ˈstɪfli ˈpɔɪntəd wɪð ɪt, bʌt hi wʌz ɪmpɚtɚbəbəl.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>b. hi sæt kraʊʧt, ˈrɛdi fɔr ðə dɛθ-sprɪŋ, æz hi hæd sæt fɔr ˈaʊərz.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>c. ɪt wʌz naɪt-bʌt ðæt meɪd noʊ ˈdɪfərəns-ɔl taɪmz wɚ æz wʌn tu ðə kæt wɛn hi wʌz ɪn weɪt fɔr preɪ.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>d. ðɛn, tu, hi wʌz ˈʌndər noʊ kənˈstreɪnt ʌv ˈhjumən wɪl, fɔr hi wʌz ˈlɪvɪŋ əˈloʊn ðæt ˈwɪntər.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>e. ˈnoʊˌwɛr ɪn ðə wɚld wʌz ˈɛni vɔɪs ˈkɔlɪŋ hɪm; ɑn noʊ hɑrθ wʌz ðɛr ə ˈweɪtɪŋ dɪʃ.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>f. hi wʌz kwaɪt fri ɪkˈsɛpt fɔr hɪz oʊn dɪˈzaɪərz, wɪʧ tirɑnaɪzd ˈoʊvər hɪm wɛn ənˈsætɪsfaɪd æz naʊ.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>g. ðə kæt wʌz ˈvɛri ˈhʌŋgri-ˈɔlˌmoʊst ˈfæmɪʃt, ɪn fækt.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>h. fɔr deɪz ðə ˈwɛðər hæd bɪn ˈvɛri ˈbɪtər, ænd ɔl ðə ˈfibələr waɪld θɪŋz wɪʧ wɚ hɪz preɪ baɪ ɪnˈhɛrətəns, ðə bɔrn sɚfs tu hɪz ˈfæməli, hæd kɛpt,,,,.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>i. ...fɔr ðə moʊst pɑrt, ɪn ðɛr ˈbɚoʊz ænd nɛsts, ænd ðə kæts lɔŋ hʌnt hæd əˈveɪld hɪm ˈnʌθɪŋ</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>j. bʌt hi ˈweɪtəd wɪð ði ˌɪnkənˈsivəbəl ˈpeɪʃəns ænd pɚsɪstɛnsi ʌv hɪz reɪs; bɪˈsaɪdz, hi wʌz ˈsɚtən.</Form.Label>
                                                    <Form.Control placeholder="enter English" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubmitcol'>
                                    <Button className='exercisesubmit'>Submit</Button>
                                </Col>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                    <ListGroup.Item className='exercisetwo exercise'>
                        <Container>
                            <Row>
                                <Col className='exercisetitle text-center'>
                                    Exercise 2: English to IPA
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisequestion'>
                                    1. Write the following English words in IPA:
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>a. hello</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>b. happy</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>c. shut</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>d. year</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>e. queen</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                                <Col className='exercisesubquestion'>
                                    <Form>
                                        <Form.Row>
                                            <Col>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>f. change</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>g. weeping</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>h. garage</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>i. bought</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                                <div className='exerciseinput'>
                                                    <Form.Label column>j. sparkle</Form.Label>
                                                    <Form.Control placeholder="" />
                                                </div>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisesubmitcol'>
                                    <Button className='exercisesubmit'>Submit</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='exercisequestion'>
                                    2. Find your favorite text in English and transcribe a few sentences (or more) in IPA.
                                </Col>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        )
    }
}

export default PhoneticsExercises;