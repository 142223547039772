import React from 'react';
import './PhoneticsQuiz.scss';
import { Form, ListGroup, Container, Row, Col, Button } from 'react-bootstrap';

class PhoneticsQuiz extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <h1 className='text-center quiztitle'>A. Quiz</h1>
                <Container>
                    <Row>
                        <Col>
                            <h3 className='text-center quizsectitle'>True/False</h3>
                            <ListGroup>
                                <ListGroup.Item className='truefalse'>
                                    <p>1. MRI uses a gum applied to the subject's mouth.</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <ListGroup.Item className='truefalse'>
                                    <p>2. In Source-Filter theory, the lungs are the source.</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <ListGroup.Item className='truefalse'>
                                    <p>3. Consonants are described in terms of their place, manner and rounding..</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <ListGroup.Item className='truefalse'>
                                    <p>4. Non-Pulmonic consonants are produced with airflow from the lungs.</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <ListGroup.Item className='truefalse'>
                                    <p>5. Vowels may be nasalized and lengthened.</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <ListGroup.Item className='truefalse'>
                                    <p>6. In IPA, the letter 'y' makes the first sound in the word 'yes'.</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <ListGroup.Item className='truefalse'>
                                    <p>7. In the worlds languages, the sounds 'p', 't' and 'k' are extremely common.</p>
                                    <Form>
                                        <Form.Check inline label='True' type={'radio'} id={`inline-radio-true`} />
                                        <Form.Check inline label='False' type={'radio'} id={`inline-radio-false`} />
                                    </Form>
                                </ListGroup.Item>
                                <Button>Submit</Button>
                            </ListGroup>
                        </Col>
                        <Col>
                            <h3 className='text-center quizsectitle'>Multiple Choice</h3>
                            <ListGroup>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>8. Which of the following experimental techniques uses sound waves to create an image?</p>
                                        <Form.Check inline label='a. Palatography' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. EMA' type={'radio'} id={`inline-radio-b`} />
                                        <br />
                                        <Form.Check inline label='c. Ultrasound' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. MRI' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>9. Which of the following is the source in source-filter theory?</p>
                                        <Form.Check inline label='a. the lungs' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. the upper vocal tract' type={'radio'} id={`inline-radio-b`} />
                                        <Form.Check inline label='c. the brain' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. the heart' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>10. Which of the following is NOT the name of a consonant place of articulation?</p>
                                        <Form.Check inline label='a. uvular' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. alveolar' type={'radio'} id={`inline-radio-b`} />
                                        <Form.Check inline label='c. glottal' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. teeth' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>11. Which of the following is NOT a type of non-pulmonic consonant?</p>
                                        <Form.Check inline label='a. fricatives' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. clicks' type={'radio'} id={`inline-radio-b`} />
                                        <br />
                                        <Form.Check inline label='c. implosives' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. ejectives' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>12. Which of the following is NOT one of the major ways to describe vowels?</p>
                                        <Form.Check inline label='a. height' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. location' type={'radio'} id={`inline-radio-b`} />
                                        <br />
                                        <Form.Check inline label='c. rounding' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. backness' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>13. Which of the following letters is NOT used to transcribe English in IPA?</p>
                                        <Form.Check inline label='a. /q/' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. /s/' type={'radio'} id={`inline-radio-b`} />
                                        <Form.Check inline label='c. /b/' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. /j/' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <ListGroup.Item className='multiplechoice'>
                                    <p>14. The world language with the most consonants has about how many?</p>
                                        <Form.Check inline label='a. 10' type={'radio'} id={`inline-radio-a`} />
                                        <Form.Check inline label='b. 30' type={'radio'} id={`inline-radio-b`} />
                                        <Form.Check inline label='c. 50' type={'radio'} id={`inline-radio-c`} />
                                        <Form.Check inline label='d. over 100' type={'radio'} id={`inline-radio-d`} />
                                </ListGroup.Item>
                                <Button>Submit</Button>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default PhoneticsQuiz;